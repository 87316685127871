import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import { Button, Row, Col, Form, Alert } from "react-bootstrap";
import AADService from "../funcionesAuth.js";

function S3View() {
    const [files, setFiles] = useState([]);
    const [groupedFiles, setGroupedFiles] = useState({});
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [alert, setAlert] = useState({ show: false, message: "", variant: "" });
    const [folder, setFolder] = useState("");
    const [search, setSearch] = useState("");
    const AzureADService = new AADService();
    const token = AzureADService.getToken();

    useEffect(() => {
        fetchFiles();
    }, []);

    const fetchFiles = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}administrador/s3/files/`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (!response.ok) throw new Error("Error fetching files");
            const data = await response.json();
            setFiles(data.files || []);
            setGroupedFiles(groupFilesByFolder(data.files || []));
        } catch (error) {
            setAlert({ show: true, message: "Error fetching files", variant: "danger" });
        }
    };

    const groupFilesByFolder = (files) => {
        const grouped = {};
        files.forEach((file) => {
            const parts = file.split("/");
            const folder = parts.length > 1 ? parts[0] : "Archivos sin carpeta";
            if (!grouped[folder]) grouped[folder] = [];
            grouped[folder].push(file);
        });
        return grouped;
    };

    const handleUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const formData = new FormData();
        formData.append("file", file);
        formData.append("folder", folder.trim());

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}administrador/s3/files/`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            });
            if (!response.ok) throw new Error("Error uploading file");
            fetchFiles();
            setAlert({ show: true, message: "File uploaded successfully", variant: "success" });
        } catch (error) {
            setAlert({ show: true, message: "Error uploading file", variant: "danger" });
        }
    };

    const handleDelete = async () => {
        if (!window.confirm("¿Está seguro de eliminar los archivos seleccionados?")) return;
    
        try {
            for (const file of selectedFiles) {
                await fetch(`${process.env.REACT_APP_API_URL}administrador/s3/files/?key=${encodeURIComponent(file)}`, {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                });
            }
            fetchFiles();
            setSelectedFiles([]);
            setAlert({ show: true, message: "Files deleted successfully", variant: "success" });
        } catch (error) {
            setAlert({ show: true, message: "Error deleting files", variant: "danger" });
        }
    };
    

    const handleDownload = async (fileName) => {
        try {
            const encodedFileName = encodeURIComponent(fileName);
            const response = await fetch(`${process.env.REACT_APP_API_URL}administrador/s3/download/?key=${encodedFileName}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (!response.ok) throw new Error("Error fetching download URL");
    
            const data = await response.json();
            if (!data.download_url) throw new Error("Invalid download URL");
            
            window.location.href = data.download_url;
        } catch (error) {
            console.error(error);
            setAlert({ show: true, message: "Error downloading file", variant: "danger" });
        }
    };
    

    return (
        <div>
            {alert.show && <Alert variant={alert.variant}>{alert.message}</Alert>}
            <Row className="mb-3">
                <Col>
                    <Form.Control type="text" placeholder="Folder name" value={folder} onChange={(e) => setFolder(e.target.value)} />
                </Col>
                <Col>
                    <Form.Control type="file" onChange={handleUpload} />
                </Col>
                <Col>
                    <Button variant="danger" onClick={handleDelete} disabled={selectedFiles.length === 0}>Delete Selected</Button>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <Form.Control type="text" placeholder="Search file" value={search} onChange={(e) => setSearch(e.target.value)} />
                </Col>
            </Row>
            <TableContainer component={Paper} style={{ maxHeight: "500px", overflowY: "auto" }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Select</TableCell>
                            <TableCell>Folder</TableCell>
                            <TableCell>File Name</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.entries(groupedFiles).map(([folder, files]) => (
                            <React.Fragment key={folder}>
                                <TableRow style={{ backgroundColor: "#f1f1f1", fontWeight: "bold" }}>
                                    <TableCell colSpan={4}>{folder}</TableCell>
                                </TableRow>
                                {files.filter(file => file.toLowerCase().includes(search.toLowerCase())).map((file) => {
                                    const fileName = file.split("/").pop();
                                    return (
                                        <TableRow key={file}>
                                            <TableCell>
                                                <Checkbox
                                                    checked={selectedFiles.includes(file)}
                                                    onChange={() => setSelectedFiles(prev => prev.includes(file) ? prev.filter(f => f !== file) : [...prev, file])}
                                                />
                                            </TableCell>
                                            <TableCell>{folder}</TableCell>
                                            <TableCell>{fileName}</TableCell>
                                            <TableCell>
                                                <Button variant="primary" onClick={() => handleDownload(file)}>Download</Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default S3View;
